import {Component, HostBinding} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  @HostBinding('class.loading') loading = true;

  constructor(public router: Router) {
    this.loading = false;
  }
}
