import { Component, OnInit } from '@angular/core';
import { FunctionsService } from '../../../providers/functions.service';
import { AuthService } from '../../../providers/auth.service';

@Component({
  selector: 'app-profile-subscription-modal',
  templateUrl: './profile-subscription-modal.component.html',
  styleUrls: ['./profile-subscription-modal.component.scss']
})
export class ProfileSubscriptionModalComponent implements OnInit {
  boleto;

  constructor(private functions: FunctionsService, private auth:AuthService) { }

  ngOnInit() {
  }

  boletoAction(){
    const callable = this.functions.boleto()
    this.boleto = callable({
      email: this.auth.af.auth.currentUser.email,
      userId: this.auth.af.auth.currentUser.uid
    })
  }
}
