import { Injectable } from '@angular/core';
import { AngularFireDatabase  } from '@angular/fire/database';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class RefService {

  constructor(private ref:AngularFireDatabase,private translateService: TranslateService) { }

  private get BaseRef(){
    return this.ref.database.ref()
  }
  private get BaseV2Ref(){
    return this.ref.database.ref().child('v2')
  }
  private get ModelsRef(){
    return this.BaseRef.child('models').child(this.language)
  }
  private get language(){
    switch(this.translateService.currentLang){
      case 'pt':
      return this.translateService.currentLang
      case 'es':
      return this.translateService.currentLang
      case 'en':
      return this.translateService.currentLang
      default:
      return 'pt'
    }
  }
  userRef(uid){
    return this.ref.database.ref().child('users').child(uid)
  }
  get classesRef(){
    return this.ModelsRef.child('classes')
  }
  classeSecoesRef(idClasse:string){
    return this.ModelsRef.child('classe-secoes').child(idClasse)
  }
  classeEspecialidadesRef(idGrupo:string){
    return this.ModelsRef.child('classes-especialidades').child(idGrupo)
  }
  categoriaClassesRef(categoria:string){
    return this.ModelsRef.child('categoria-classes').child(categoria)
  }
  get especialidadesRef(){
    return this.ModelsRef.child('especialidades')
  }
  especialidadeItensRef(idEspecialidade:string){
    return this.ModelsRef.child('especialidade-itens').child(idEspecialidade)
  }
  categoriaEspecialidadesRef(categoria:string){
    return this.ModelsRef.child('categoria-especialidades').child(categoria)
  }
  get mestradosRef(){
    return this.ModelsRef.child('mestrados')
  }
  mestradoEspecialidadesRef(id:string){
    return this.ModelsRef.child('mestrado-especialidades').child(id)
  }
  userClassesRef(idUser:string){
    return this.BaseRef.child('user-classes').child(idUser)
  }
  userEspecialidadesRef(idUser:string){
    return this.BaseRef.child('user-especialidades').child(idUser)
  }
  userMestradosRef(idUser:string){
    return this.BaseRef.child('user-mestrados').child(idUser)
  }
  userEspecialidadeItemRespostasRef(idUser:string,idEspecialidade:string){
    return this.BaseRef.child('user-especialidade-item-resposta').child(idUser).child(idEspecialidade)
  }
  userEspecialidadeItemRespostaVersaoRef(idUser:string,idEspecialidade:string,idTitulo,idPergunta,idVersao){
    return this.BaseRef.child('user-especialidade-item-resposta-versoes').child(idUser).child(idEspecialidade).child(idTitulo).child(idPergunta).child(idVersao)
  }
  userEspecialidadeItemAnexosRef(idUser:string,idClasse:string,idTitulo,idPergunta){
    return this.BaseRef.child('user-especialidade-item-anexo').child(idUser).child(idClasse).child(idTitulo).child(idPergunta)
  }
  userClasseItemRespostasRef(idUser:string,idClasse:string){
    return this.BaseRef.child('user-classe-item-resposta').child(idUser).child(idClasse)
  }
  userClasseItemRespostaVersaoRef(idUser:string,idClasse:string,idTitulo,idPergunta,idVersao){
    return this.BaseRef.child('user-classe-item-resposta-versoes').child(idUser).child(idClasse).child(idTitulo).child(idPergunta).child(idVersao)
  }
  userClasseItemAnexosRef(idUser:string,idClasse:string,idTitulo,idPergunta){
    return this.BaseRef.child('user-classe-item-anexo').child(idUser).child(idClasse).child(idTitulo).child(idPergunta)
  }

// USERS MESTRADOS
  userMestradosEspecialidadesRef(idUser:string,idMestrado:string){
    return this.BaseV2Ref.child('user-mestrado-especialidades').child(idUser).child(idMestrado)
  }
  
}