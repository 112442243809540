import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {RouterModule, PreloadAllModules} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CustomFormsModule} from 'ng2-validation';

import {SidemenuModule} from './modules/sidemenu/sidemenu.module';
import {ResizeModule} from './modules/resize/resize.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {OverlayModule} from '@angular/cdk/overlay';

import {PortalModule} from '@angular/cdk/portal';
import {NgxEchartsModule} from 'ngx-echarts';
import {AppComponent} from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { RefService } from './providers/ref.service';
import { RealtimeService } from './providers/realtime.service';
import { AuthService } from './providers/auth.service';
import { UploadService } from './providers/upload.service';
import { FunctionsService } from './providers/functions.service';

import { ProfileCreateModalComponent } from './pages/profile-page/profile-create-modal/profile-create-modal.component';
import { ProfileModalComponent } from './pages/profile-page/profile-modal/profile-modal.component';
import { ProfileSubscriptionModalComponent } from './pages/profile-page/profile-subscription-modal/profile-subscription-modal.component';

import { APP_ROUTES } from './app.routes';
import { MaterialModule } from './modules/material/material.module';
import { TranslateSharedModule } from './modules/translate/translate.module';
import { CommonModule } from '@angular/common';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { MessagingService } from './providers/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FirestoreService } from './providers/firestore.service';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { NavService } from './providers/nav.service';
import { ShortLinksService } from './providers/short-links.service';
import { ProfileCreateNicknameComponent } from './pages/profile-page/profile-create-nickname/profile-create-nickname.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    MainPageComponent,
    ProfileModalComponent,
    ProfileCreateModalComponent,
    ProfileSubscriptionModalComponent,
    ProfileCreateNicknameComponent,
    // TestPageComponent,
  ],
  entryComponents: [
    ProfileModalComponent,
    ProfileCreateModalComponent,
    ProfileSubscriptionModalComponent,
    ProfileCreateNicknameComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    PortalModule,
    SidemenuModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    CustomFormsModule,
    NgxEchartsModule,
    ResizeModule,
    HttpClientModule,
    TranslateSharedModule,
    RouterModule.forRoot(APP_ROUTES, {preloadingStrategy: PreloadAllModules}),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    MaterialModule,
  ],
  providers: [
    RealtimeService,
    RefService,
    AuthService,
    UploadService,
    FunctionsService,
    MessagingService,
    FirestoreService,
    AngularFireAuthGuard,
    NavService,
    ShortLinksService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
