import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ShortLinksService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  create(link:string, prefix: string,socialTitle:string,socialDescription:string,socialImageLink:string) {
    const data = {
      "dynamicLinkInfo": {
        "domainUriPrefix": prefix,
        "link": link,
        "androidInfo": {
          "androidPackageName": "com.udbv.dsa",
          // "androidFallbackLink": string,
          // "androidMinPackageVersionCode": string
        },
        "iosInfo": {
          "iosBundleId": "string",
          // "iosFallbackLink": string,
          // "iosCustomScheme": string,
          // "iosIpadFallbackLink": string,
          // "iosIpadBundleId": string,
          // "iosAppStoreId": string
        },
        // "navigationInfo": {
        //   "enableForcedRedirect": true,
        // },
        // "analyticsInfo": {
        //   "googlePlayAnalytics": {
        //     "utmSource": string,
        //     "utmMedium": string,
        //     "utmCampaign": string,
        //     "utmTerm": string,
        //     "utmContent": string,
        //     "gclid": string
        //   },
          // "itunesConnectAnalytics": {
          //   "at": string,
          //   "ct": string,
          //   "mt": string,
          //   "pt": string
          // }
        // },
        "socialMetaTagInfo": {
          "socialTitle": socialTitle,
          "socialDescription": socialDescription,
          "socialImageLink": socialImageLink
        }
      },
      "suffix": {
        "option": "SHORT"
      }
    }

    return this.http.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebase.apiKey}`, data, this.httpOptions)
  }
}