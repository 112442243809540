import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Injectable()
export class AuthService {

  // authState: any = null;
  authState: firebase.User = null;

  constructor(public af: AngularFireAuth) {
    this.af.authState.subscribe((auth) => {
      this.authState = auth
    });
  }

  get getUid():string{
    return this.af.auth.currentUser.uid
  }
 
  async isPlus(){
    const value = await firebase.auth().currentUser.getIdTokenResult(true)
    return value.claims.plus ? true : false
  }

  facebook(){
    var provider = new firebase.auth.FacebookAuthProvider();
    return this.af.auth.signInWithRedirect(provider)
  }
  
  google(){
    var provider = new firebase.auth.GoogleAuthProvider();
    return this.af.auth.signInWithRedirect(provider)
  }

  email(email:string, password:string) {
    return this.af.auth.signInWithEmailAndPassword(email, password)
  }

  create(email:string, password:string){
    return this.af.auth.createUserWithEmailAndPassword(email,password)
  }

  resetPassword(email: string) {
    return this.af.auth.sendPasswordResetEmail(email)
  }

  logout() {
    return this.af.auth.signOut()
  }
}
