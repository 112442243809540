import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { User } from '../interfaces/user';
import { Device } from '../interfaces/device';
import { Classe } from '../modules/classes/interfaces/classe';
import { Especialidade } from '../classes/especialidade';
import { Mestrado } from '../modules/mestrados/interfaces/mestrado';

@Injectable()
export class RealtimeService {

  constructor(public db: AngularFireDatabase, public auth:AuthService) {}
  
  get user() {
    return this.db.object<User>(`users/${this.auth.af.auth.currentUser.uid}`)
  }

  get device(){
    let UUID
    if(localStorage.getItem('UUID') != null){
      UUID = localStorage.getItem('UUID')
    }else{
      localStorage.setItem('UUID', this.guid());
      UUID = localStorage.getItem('UUID')
    }
    return this.db.object<Device>(`devices/${this.auth.af.auth.currentUser.uid}/${UUID}`)
  }

  classes(){
    return this.db.list<Classe>(`user-classes/${this.auth.af.auth.currentUser.uid}`)
  }

  especialidades(){
    return this.db.list<Especialidade>(`user-especialidades/${this.auth.af.auth.currentUser.uid}`)
  }

  mestrados(){
    return this.db.list<Mestrado>(`user-mestrados/${this.auth.af.auth.currentUser.uid}`)
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}