import { Component, OnInit } from '@angular/core';
import { User } from '../../../interfaces/user';
import { AuthService } from '../../../providers/auth.service';
import { RealtimeService } from '../../../providers/realtime.service';
import { MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {
  
  user:User  = {
    name: 'carregando',
    email: 'carregando',
    photo: '/assets/img/profile/2.png'
  }
  form:FormGroup
  constructor(private auth:AuthService,
              private db:RealtimeService,
              private dialog:MatDialog) {}

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(),
      name: new FormControl(),
      email: new FormControl()
    });
    this.auth.af.authState.subscribe(current => {
      if(current){        
        this.db.user.valueChanges().subscribe(user => {
          if(user != null){
            this.user = user
            this.form.setValue({
              id: current.uid,
              email: user.email, 
              name: user.name,
            });
          }else{
            this.user = {
              name: 'Ocorreu um erro',
              email: 'Ocorreu um erro',
              photo: '/assets/img/profile/2.png'
            }          
          }
        })
      }
    })
  }
}