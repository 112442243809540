import { Injectable } from "@angular/core"
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable()
export class FunctionsService {

  constructor(private fns: AngularFireFunctions) {}
  
  boleto(){
    return this.fns.httpsCallable('boleto')
  }

  link(){
    return this.fns.httpsCallable('link')
  }
}