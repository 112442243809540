import { Component, OnInit, HostBinding } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { routerAnimation } from '../../utils/page.animation';
import { MatSnackBar } from '@angular/material';
import { MessagingService } from '../../providers/messaging.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [routerAnimation]
})
export class LoginPageComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = true;
  emailSign = new FormControl('', [Validators.required, Validators.email]);
  passwordSign = new FormControl('', [Validators.required, Validators.minLength(6)]);
  emailCreate = new FormControl('', [Validators.required, Validators.email]);
  passwordCreate = new FormControl('', [Validators.required, Validators.minLength(6)]);
  passwordConfirmCreate = new FormControl('', [Validators.required, Validators.minLength(6)]);
  loading = false
  hide = true;

  constructor(private auth:AuthService,
              private router:Router,
              private snack: MatSnackBar,
              private messaging:MessagingService) {}

  ngOnInit() {
    this.auth.af.authState.subscribe(user => {
      if(user){           
        // this.messaging.requestPermission()
        this.router.navigate(['/'])
      }
    })
  }

  google(){
    this.loading = true
    this.auth.google()
    .then(() => {
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.snack.open(error.message,null,{duration:2000})
    })
  }

  facebook(){
    this.loading = true
    this.auth.facebook()
    .then(() => {
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.snack.open(error.message,null,{duration:2000})
    })
  }

  email(){
    this.loading = true
    this.auth.email(this.emailSign.value,this.passwordSign.value)
    .then(() => {
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.snack.open(error.message,null,{duration:2000})
    })
  }

  create(){
    if(!this.emailCreate.valid){
      this.snack.open('Não é um email válido',null,{duration:2000})
      return
    }else if(!this.passwordCreate.valid){
      this.snack.open('Não é uma senha válida',null,{duration:2000})
      return
    }
    else if(this.passwordConfirmCreate.value != this.passwordCreate.value){
      this.snack.open('Senhas não são iguais',null,{duration:2000})
      return
    }
    
    this.auth.create(this.emailCreate.value,this.passwordCreate.value)
    .then(() => {
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.snack.open(error.message,null,{duration:2000})
    })
  }
}
