import { Component, OnInit, AfterViewInit, HostBinding, HostListener } from '@angular/core';
import { ResizeService } from '../../modules/resize/resize.service';
import { routerAnimation } from '../../utils/page.animation';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { RealtimeService } from '../../providers/realtime.service';
import { User } from '../../interfaces/user';
import { MatDialog, MatSnackBar, MatBottomSheetRef, MatBottomSheet } from '@angular/material';
import { ProfileCreateModalComponent } from '../profile-page/profile-create-modal/profile-create-modal.component';
import { ProfileModalComponent } from '../profile-page/profile-modal/profile-modal.component';
import { ProfileSubscriptionModalComponent } from '../profile-page/profile-subscription-modal/profile-subscription-modal.component';
import { MessagingService } from '../../providers/messaging.service';
import { FirestoreService } from '../../providers/firestore.service';
import { Observable } from 'rxjs';
import { ProfileCreateNicknameComponent } from '../profile-page/profile-create-nickname/profile-create-nickname.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [routerAnimation]
})
export class MainPageComponent implements OnInit, AfterViewInit {

  @HostBinding('@routerAnimation') routerAnimation = false;
  @HostBinding('class.dark-theme') darkTheme = false;
  _sidenavMode = 'side';
  _boxedLayout = false;
  sideNavOpened = false;
  classes = 0
  especialidades = 0
  mestrados = 0
  user: Observable<User>
  //   = {
  //   name: 'carregando',
  //   email: 'carregando',
  //   photo: '/assets/img/profile/2.png'
  // }
  
  constructor(public resizeService: ResizeService,
              private router:Router,
              private auth:AuthService,
              private realtime:RealtimeService,
              private messaging:MessagingService,
              private firestore:FirestoreService,
              private snack:MatSnackBar,
              private bottomSheet: MatBottomSheet,
              private dialog:MatDialog) {
    auth.isPlus().then((plus) => { this.darkTheme = plus })
    document.querySelector('body').classList.add('plus-dark-theme');
  }

  ngOnInit() {
    this.messaging.listen()
    // this.auth.af.authState.subscribe(current => {
    //   if(current){        
    //     this.realtime.user(current.uid).subscribe(user => {
    //       if(user != null){
    //         this.user = user
    //         this.realtime.userClassesList(current.uid).subscribe(classes => this.classes = classes.length)
    //         this.realtime.userEspecialidadesList(current.uid).subscribe(especialidades => this.especialidades = especialidades.length)
    //         this.realtime.userMestradosList(current.uid).subscribe(mestrados => this.mestrados = mestrados.length)
    //       }else{
    //         this.dialog.open(ProfileCreateModalComponent, {width: '300px',disableClose:true})
    //       }
    //     })
    //   }else{
    //     this.router.navigate(['/login'])
    //   }
    // })
  }

  ngAfterViewInit(): void {
    this.onResize();
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
    setTimeout(() => this.sideNavOpened = true, 0);
    this.auth.af.authState.subscribe(current => {
      if(current){        
        this.user = this.firestore.user().valueChanges()
        this.bottomSheet.open(ProfileCreateNicknameComponent);

        // .subscribe(user => {
        //   if(user != null){
        //     this.user = user
        //     this.realtime.classes().valueChanges().subscribe(classes => this.classes = classes.length)
        //     this.realtime.especialidades().valueChanges().subscribe(especialidades => this.especialidades = especialidades.length)
        //     this.realtime.mestrados().valueChanges().subscribe(mestrados => this.mestrados = mestrados.length)
        //   }else{
        //     this.dialog.open(ProfileCreateModalComponent, {width: '300px',disableClose:true})
        //   }
        // })
      }else{
        this.router.navigate(['/login'])
      }
    })
  }

  set sidenavMode(val) {
    this._sidenavMode = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get sidenavMode() {
    return this._sidenavMode;
  }

  set boxedLayout(val) {
    this._boxedLayout = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get boxedLayout() {
    return this._boxedLayout;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      if (window.innerWidth < 800) {
        this.sideNavOpened = false;
        this._sidenavMode = 'over';
      }
    }, 500);

    // if (window.innerWidth < 800) {
    //   this.sideNavOpened = false;
    //   this._sidenavMode = 'over';
    // }
  }

  logout(){
    this.messaging.deleteToken()
    this.auth.logout()
  }
  profile(){
    this.dialog.open(ProfileModalComponent, { width: '280px'})
  }
  subscription(){
    this.dialog.open(ProfileSubscriptionModalComponent, { width: '250px'})
  }
}