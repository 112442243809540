import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { FirestoreService } from '../../../providers/firestore.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-create-nickname',
  templateUrl: './profile-create-nickname.component.html',
  styleUrls: ['./profile-create-nickname.component.scss']
})
export class ProfileCreateNicknameComponent implements OnInit {
  
  nick = new FormControl('', [Validators.required, Validators.email]);

  constructor(private bottomSheetRef: MatBottomSheetRef<ProfileCreateNicknameComponent>,public firestore:FirestoreService) {}

  ngOnInit() {
  }

  save(){
    this.firestore.user().update({nick: this.nick.value})
  }
}
