import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideitemComponent} from './item/sideitem.component';
import { SidemenuComponent } from './menu/sidemenu.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatBadgeModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatBadgeModule
  ],
  declarations: [SidemenuComponent, SideitemComponent],
  exports: [SidemenuComponent, SideitemComponent]
})
export class SidemenuModule {
}
