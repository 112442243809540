import { Injectable } from "@angular/core";
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable()
export class UploadService {

  constructor(private storage:AngularFireStorage) {}
  
  private get BaseRef(){
    // Produção
    return this.storage.ref('')
  }
  CompanyImportUsers(idCompany:string){
    return this.BaseRef.child('import-files').child(idCompany)
  }
  logoRef(){
    return this.BaseRef.child('logo')
  }
  settingsUserImportUsersRef(){
    return this.BaseRef.child('settings').child('users').child('import-users')
  }
}