import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { Classe } from '../interfaces/classe';
import { AuthService } from './auth.service';
import { Token } from '../interfaces/token';
import { ClasseCategory } from '../interfaces/classeCategory';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FirestoreService {

  constructor(private store: AngularFirestore, private auth: AuthService, private translate: TranslateService) { }

  get lang(){
    return this.translate.currentLang
  }
  users(){
    return this.store.collection<User>('users')
  }
  user(userId?: string){
    if(userId){
      return this.users().doc<User>(userId)
    }
    return this.users().doc<User>(this.auth.authState.uid)
  }
  devices(){
    return this.user().collection<Token>('devices')
  }
  device(device: string){
    return this.devices().doc<Token>(device)
  }

  classes(category?:string){
    if(category){
      return this.store.collection('classes',ref => ref.where(`category.link.${this.lang}.link`,'==',category).orderBy('age','asc'))
    }
    return this.store.collection('classes',ref => ref.orderBy('category.position','asc'))
  }

  classe(classe:string){
    return this.store.collection('classes',ref => ref.where(`link.${this.lang}.link`, '==', classe).limit(1))
  }
  
  classeSecoes(classeId:string){
    return this.classes().doc(classeId).collection('sections',ref => ref.orderBy('position','asc'))
  }
  classeSectionItems(classeId:string, sectionId:string){
    return this.classes().doc(classeId).collection('sections').doc(sectionId).collection('items', ref => ref.orderBy('position','asc'))
  }
  classesCategories(){
    return this.store.collection('classes-categories')
  }
  classesCategoryByLink(link: string){
    return this.store.collection('classes-categories-by-link').doc(link).collection<Classe>('classes')
  }
  
  userClasses(){
    return this.user().collection('classes')//store.collection<Classe>('classes')
  }
  userClassesByCategory(link: string){
    return this.user().collection('classes',ref => ref.where(`category.link.${this.lang}`,'==',link).limit(1))//store.collection<Classe>('classes')
  }
  userClasseByLink(link: string){
    console.log(this.lang)
    console.log(`link.${this.lang}`)
    return this.user().collection('classes',ref => ref.where(`link.${this.lang}`,'==',link).limit(1))//store.collection<Classe>('classes')
  }
  // projects(profile?:Profile){
  //   if(profile){
  //     let collection:CollectionReference = this.store.collection<Project>('projects').ref
  //         collection
  //         .where('agent', '==', null)
  //         // .where('words.qty', '<=', profile.preference.max)
  //         // .where('experience.id', '==', profile.experience.id)
  //         // profile.categories.forEach(category => {
  //         //   collection.where('category.id', '==', category.id)
  //         //   category.subcategories.forEach(subcategory => {
  //         //     collection.where('subcategory.id', '==', subcategory.id)
  //         //   })
  //         // })
  //     return this.store.collection<Project>(collection)
  //   }
  //   return this.store.collection<Project>('projects')
  // }
  // projectsActive(){
  //   return this.client().collection<Project>('projects-active')
  // }
  // projectsArchived(){
  //   return this.client().collection<Project>('projects-archived')
  // }
  // projectsCompleted(){
  //   return this.client().collection<Project>('projects-completed')
  // }


  // contractsActive(){
  //   return this.agent().collection<Contract>('contracts-active')
  // }
  // contractsCompleted(){
  //   return this.agent().collection<Contract>('contracts-completed')
  // }
  // contractsCancelled(){
  //   return this.agent().collection<Contract>('contracts-cancelled')
  // }
  // contractActive(contractId:string){
  //   return this.contractsActive().doc<Contract>(contractId)
  // }
  // contractCompleted(contractId:string){
  //   return this.contractsCompleted().doc<Contract>(contractId)
  // }
  // contractCancelled(contractId:string){
  //   return this.contractsCancelled().doc<Contract>(contractId)
  // }
  // contractActiveReviewMessages(contractId:string){
  //   return this.contractActive(contractId).collection<ReviewMessage>('review-messages', q => q.orderBy('date', 'asc'))
  // }
  // projectActive(projectId:string){
  //   return this.projectsActive().doc<Project>(projectId)
  // }
  // projectArchived(projectId:string){
  //   return this.projectsArchived().doc<Project>(projectId)
  // }
  // projectCompleted(projectId:string){
  //   return this.projectsCompleted().doc<Project>(projectId)
  // }
  // project(projectId:string){
  //   return this.projects().doc<Project>(projectId)
  // }
  // categories(){
  //   return this.store.collection<Category>('categories')
  // }
  // subcategories(categoryId:string){
  //   return this.categories().doc(categoryId).collection<Subcategory>('subcategories')
  // }
  // wordsQty(){
  //   return this.store.collection<WordQty>('words-qty')
  // }
  // reviewMessages(projectId:string){
  //   return this.projectActive(projectId).collection<ReviewMessage>('review-messages', q => q.orderBy('date', 'asc'))
  // }
  // contracts(){
  //   return this.store.collection<Contract>('contracts')
  // }
  // contract(contractId:string){
  //   return this.contracts().doc<Contract>(contractId)
  // }
  // profiles(){
  //   return this.store.collection<Profile>('profiles')
  // }
  // profile(){
  //   return this.profiles().doc<Profile>(this.auth.authState.uid)
  // }
  // experiences(){
  //   return this.store.collection<Experience>('experiences')
  // }
  // preferences(){
  //   return this.store.collection<Preference>('preferences')
  // }

  // clients(){
  //   return this.store.collection<Client>('clients')
  // }
  // client(id?: string){
  //   if(id){
  //     return this.clients().doc<Client>(id)
  //   }
  //   return this.clients().doc<Client>(this.auth.authState.uid)
  // }

  // agents(){
  //   return this.store.collection<Agent>('agents')
  // }
  // agent(id?: string){
  //   if(id){
  //     return this.agents().doc<Agent>(id)
  //   }
  //   return this.agents().doc<Agent>(this.auth.authState.uid)
  // }
  // versions(contractId:string){
  //   return this.contractActive(contractId).collection<Version>('content-versions')
  // }
  // version(contractId: string, versionId: string){
  //   return this.versions(contractId).doc<Version>(versionId)
  // }
}
// category
// "Classes Regulares"
// createdAt
// 3 de novembro de 2019 22:00:00 UTC-3
// (timestamp)
// id
// "E5dDsxlRlmBbgUc43wY7"
// itemsCount
// 25
// name
// "Amigo"

// id
// "PNW9MT9PE81JRZnviJ1a"
// link
// "1_-_GERAL"
// name
// "I - Geral"
// position
// 0