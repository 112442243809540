import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { RealtimeService } from './realtime.service';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class MessagingService {

  constructor(private afMessaging: AngularFireMessaging, private snack:MatSnackBar, private realtime: RealtimeService) { }

  deleteToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => { 
          console.log('Deleted!'); 
          this.realtime.device.remove()
          .then(() => {
            localStorage.removeItem('UUID')
          })
        },
      );
  }

  listen() {
    this.afMessaging.messages.subscribe((message) => {
      console.log(message); 
      this.snack.open('message')
    });
  }

  requestPermission() {
    this.afMessaging.requestToken.subscribe(
      (token) => { 
        console.log('Permission granted! Save to the server!', token); 
        this.realtime.device.set({token: token})
      },
        (error) => { console.error(error); },  
      );
  }
}