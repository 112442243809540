import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavService {

  navchange: EventEmitter<string> = new EventEmitter();
  requestNavchange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }


  emitNavChangeEvent(string) {
    this.navchange.emit(string);
  }

  getNavChangeEmitter() {
    return this.navchange;
  }

  emitRequestInitNavChangeEvent() {
    this.requestNavchange.emit(true);
  }

  getRequestInitNavChangeEmitter() {
    return this.requestNavchange;
  }
}
