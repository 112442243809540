import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../providers/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-profile-create-modal',
  templateUrl: './profile-create-modal.component.html',
  styleUrls: ['./profile-create-modal.component.scss']
})
export class ProfileCreateModalComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  name = new FormControl('', [Validators.required]);

  constructor(private auth:AuthService,
    public dialogRef: MatDialogRef<ProfileCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

  logout(){
    this.dialogRef.close();
    this.auth.logout()
  }
}
