import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { TestPageComponent } from './pages/test-page/test-page.component';

const plusOnly = hasCustomClaim('plus');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLogged = () => redirectLoggedInTo(['/']);
const belongsToAccount = (next) => hasCustomClaim(`plus`);

export const APP_ROUTES: Routes = [
  {path: 'login', component: LoginPageComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLogged }},
  {path: '', component: MainPageComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
    {path: '', redirectTo: 'classes', pathMatch: 'full'},
    {path: 'classes', loadChildren: './modules/classes/classes.module#ClassesModule'},
    {path: 'especialidades', loadChildren: './modules/especialidades/especialidades.module#EspecialidadesModule'},
    {path: 'mestrados', loadChildren: './modules/mestrados/mestrados.module#MestradosModule'},
  ]},
  {path: ':nickname', component: MainPageComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
    {path: '', redirectTo: 'classes', pathMatch: 'full'},
    {path: 'classes', loadChildren: './modules/classes/classes.module#ClassesModule'},
    {path: 'especialidades', loadChildren: './modules/especialidades/especialidades.module#EspecialidadesModule'},
    {path: 'mestrados', loadChildren: './modules/mestrados/mestrados.module#MestradosModule'},
  ]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {preloadingStrategy: PreloadAllModules}),
  ]
})
export class AppRoutesModule {
}